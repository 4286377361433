import { directusClient, DIRECTUS_URL } from '../config/directus';

export const timelineService = {
  getTimelines: async ({ sort = ['date'] } = {}) => {
    try {
      const response = await directusClient.items('timelines').readByQuery({
        fields: ['id', 'title', 'date', 'summary', 'content', 'image'],
        sort
      });

      return response.data.map(item => ({
        date: item.date,
        text: item.summary,
        title: item.title,
        imageUrl: item.image ? `${DIRECTUS_URL}/assets/${item.image}` : null
      }));
    } catch (error) {
      console.error('Error fetching timelines:', error);
      throw error;
    }
  }
}; 