import { directusClient, DIRECTUS_URL } from '../config/directus';

export const jobService = {
  getJobs: async () => {
    try {
      const response = await directusClient.items('job_listing').readByQuery({
        fields: [
          'id',
          'job_title',
          'job_description',
          'job_summary',
          'date_created',
          'date_updated',
          'status',
          'location'
        ],
        filter: {
          status: { _eq: 'published' }
        },
        sort: ['-date_created']
      });
      
      return response.data;
    } catch (error) {
      console.error('Error fetching jobs:', error);
      throw error;
    }
  }
}; 