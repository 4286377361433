import React from 'react';
import { Image, Header, Segment } from 'semantic-ui-react';

const Timeline = ({ events }) => {
  const getYear = (date) => new Date(date).getFullYear();
  let currentYear = null;

  return (
    <div className="timeline-container">
      {events.map((event, index) => {
        const year = getYear(event.date);
        const showYear = year !== currentYear;
        currentYear = year;

        return (
          <React.Fragment key={index}>
            {showYear && (
              <div className="timeline-year">
                <span>{year}</span>
              </div>
            )}
            <div className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}>
              <div className="timeline-marker"></div>
              <Segment className="timeline-content" raised>
                {event.imageUrl && (
                  <div className="timeline-image-container">
                    <Image 
                      src={event.imageUrl}
                      className="timeline-image"
                    />
                  </div>
                )}
                <div className="timeline-date">
                  {new Date(event.date).toLocaleDateString('vi-VN', {
                    month: 'long'
                  })}, {year}
                </div>
                <Header as='h3' className="timeline-title">
                  {event.title}
                </Header>
                <div className="timeline-text">
                  {event.text}
                </div>
              </Segment>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Timeline; 