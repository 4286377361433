import React from "react";
import { Container, Dimmer, Header, Loader, Message, Segment, Icon, Image, Label } from "semantic-ui-react";
import { postService } from "../services/posts";
import ReactMarkdown from 'react-markdown';

class NewsfeedDetail extends React.Component {
  state = {
    loading: false,
    error: null,
    data: null,
    width: window.innerWidth,
  };

  componentDidMount() {
    this.fetchPost();
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  fetchPost = async () => {
    const { match: { params } } = this.props;
    
    this.setState({ loading: true });
    try {
      const data = await postService.getPostBySlug(params.slug);
      this.setState({ 
        data, 
        loading: false 
      });
    } catch (err) {
      this.setState({ 
        error: err, 
        loading: false 
      });
    }
  };

  render() {
    const { data, error, loading, width } = this.state;
    const isMobile = width < 768;

    if (loading) {
      return (
        <Segment basic style={{ minHeight: '100vh' }}>
          <Dimmer active inverted>
            <Loader size='large'>Đang tải...</Loader>
          </Dimmer>
        </Segment>
      );
    }

    if (error) {
      return (
        <Container text style={{ marginTop: '2em' }}>
          <Message
            error
            icon='warning sign'
            header="Có lỗi xảy ra"
            content="Không thể tải bài viết. Vui lòng thử lại sau."
          />
        </Container>
      );
    }

    if (!data) {
      return (
        <Container text style={{ marginTop: '2em' }}>
          <Message
            info
            icon='search'
            header="Không tìm thấy bài viết"
            content="Không thể tìm thấy bài viết bạn yêu cầu."
          />
        </Container>
      );
    }

    return (
      <Container text style={{ 
        paddingTop: isMobile ? '1em' : '2em', 
        paddingBottom: '4em',
        paddingLeft: isMobile ? '1em' : undefined,
        paddingRight: isMobile ? '1em' : undefined
      }}>
        {/* Article Header */}
        <Header as='h1' style={{ 
          fontSize: isMobile ? '1.8em' : '2.5em', 
          fontWeight: '300',
          marginBottom: isMobile ? '0.3em' : '0.5em',
          color: '#2c3e50',
          lineHeight: '1.3'
        }}>
          {data.title}
        </Header>

        {/* Meta Information */}
        <Segment basic style={{ 
          padding: isMobile ? '0 0 1em 0' : '0 0 2em 0',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: isMobile ? '0.7em' : '1em',
          color: '#7f8c8d',
          borderBottom: '1px solid #ecf0f1',
          fontSize: isMobile ? '0.85em' : '1em'
        }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.4em' }}>
            <Icon name='calendar outline' />
            {new Date(data.date_published).toLocaleDateString('vi-VN', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </div>
          {data.type && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.4em' }}>
              <Icon name='tag' />
              <Label 
                size='tiny' 
                color='brown'
                style={{
                  margin: 0,
                  padding: '0.4em 0.8em',
                  fontSize: isMobile ? '0.85em' : '0.9em'
                }}
              >
                {data.type}
              </Label>
            </div>
          )}
          {data.category && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.4em' }}>
              <Icon name='folder outline' />
              {data.category}
            </div>
          )}
        </Segment>

        {/* Featured Image */}
        {data.image && (
          <div style={{ 
            margin: isMobile ? '1em auto' : '2em auto', 
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            maxWidth: '800px'
          }}>
            <Image 
              src={data.image}
              centered
              fluid
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: isMobile ? '300px' : '500px',
                objectFit: 'cover'
              }}
            />
          </div>
        )}

        {/* Summary */}
        {data.summary && (
          <Segment style={{
            background: '#f8f9fa',
            border: 'none',
            borderLeft: '4px solid #e67e22',
            borderRadius: '4px',
            padding: isMobile ? '1.5em' : '2em',
            margin: isMobile ? '1.5em 0' : '2em 0',
            fontSize: isMobile ? '1em' : '1.1em',
            fontStyle: 'italic',
            color: '#34495e',
            lineHeight: '1.6'
          }}>
            {data.summary}
          </Segment>
        )}

        {/* Main Content */}
        {data.content && (
          <Segment basic style={{ 
            marginTop: isMobile ? '1.5em' : '2em',
            fontSize: isMobile ? '1em' : '1.1em',
            lineHeight: '1.8',
            color: '#2c3e50',
            padding: 0
          }}>
            <div 
              className="article-content"
              dangerouslySetInnerHTML={{ __html: data.content }}
              style={{
                '& img': {
                  maxWidth: '100%',
                  height: 'auto',
                  borderRadius: '4px',
                  margin: isMobile ? '1em 0' : '1.5em 0'
                },
                '& p': {
                  marginBottom: isMobile ? '1em' : '1.5em'
                },
                '& h2': {
                  fontSize: isMobile ? '1.5em' : '1.8em',
                  marginTop: isMobile ? '1.5em' : '2em',
                  marginBottom: isMobile ? '0.8em' : '1em'
                },
                '& h3': {
                  fontSize: isMobile ? '1.3em' : '1.5em',
                  marginTop: isMobile ? '1.3em' : '1.8em',
                  marginBottom: isMobile ? '0.7em' : '0.9em'
                },
                '& ul, & ol': {
                  paddingLeft: isMobile ? '1.2em' : '2em',
                  marginBottom: isMobile ? '1em' : '1.5em'
                },
                '& li': {
                  marginBottom: '0.5em'
                },
                '& blockquote': {
                  borderLeft: '3px solid #e67e22',
                  paddingLeft: isMobile ? '1em' : '1.5em',
                  margin: isMobile ? '1em 0' : '1.5em 0',
                  fontStyle: 'italic',
                  color: '#34495e'
                }
              }}
            />
          </Segment>
        )}

        {/* Author Information */}
        {data.author && (
          <>
            <div style={{
              textAlign: 'center',
              margin: isMobile ? '3em 0 1.5em' : '4em 0 2em',
              position: 'relative'
            }}>
              <div style={{
                position: 'absolute',
                top: '50%',
                left: 0,
                right: 0,
                height: '1px',
                background: '#e0e0e0',
                zIndex: 0
              }} />
              <span style={{
                background: '#fff',
                padding: '0 1em',
                color: '#7f8c8d',
                fontSize: '0.9em',
                textTransform: 'uppercase',
                letterSpacing: '2px',
                position: 'relative',
                zIndex: 1
              }}>
                Về người viết
              </span>
            </div>
            <Segment style={{
              padding: isMobile ? '2em 1.5em' : '2.5em',
              background: '#f8f9fa',
              border: 'none',
              borderRadius: '12px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
            }}>
              <div style={{
                display: 'flex',
                alignItems: isMobile ? 'center' : 'flex-start',
                gap: '2em',
                flexDirection: isMobile ? 'column' : 'row'
              }}>
                {data.author.image && (
                  <div style={{
                    flexShrink: 0,
                    position: 'relative'
                  }}>
                    <Image
                      src={data.author.image}
                      circular
                      style={{
                        width: isMobile ? '100px' : '120px',
                        height: isMobile ? '100px' : '120px',
                        objectFit: 'cover',
                        border: '3px solid #fff',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                      }}
                    />
                  </div>
                )}
                <div style={{
                  textAlign: isMobile ? 'center' : 'left',
                  flex: 1
                }}>
                  <Header as='h3' style={{ 
                    margin: '0 0 0.5em 0', 
                    color: '#2c3e50',
                    fontSize: isMobile ? '1.5em' : '1.7em',
                    fontWeight: '500'
                  }}>
                    {data.author.name}
                  </Header>
                  {data.author.job_title && (
                    <div style={{ 
                      color: '#e67e22',
                      fontSize: isMobile ? '1em' : '1.1em',
                      marginBottom: '1em',
                      fontWeight: '500'
                    }}>
                      {data.author.job_title} tại Phước Sơn
                    </div>
                  )}
                </div>
              </div>
            </Segment>
          </>
        )}
      </Container>
    );
  }
}

export default NewsfeedDetail;
