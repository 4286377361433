import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ScrollToTop from "react-scroll-up";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { businessAreas } from "../data/businessAreas";
import home_1 from '../custom_media/home_1.JPG';
import image_CEO from '../custom_media/CEO.jpg';
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
    widescreen: 1440  // Add widescreen breakpoint
  },
})

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    {children}
  </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const HomepageLayout = () => (
  <ResponsiveContainer>
    <Segment 
      style={{ 
        padding: "2em 0em",
        transition: "all 0.3s ease",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)" 
      }}
      vertical 
      id="nextHomepage"
    >
      <Grid container stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              Tầm nhìn và Sứ mệnh
            </Header>
            <p style={{ fontSize: "1.33em", paddingLeft: "0.5" }}>
              <q>Phước Sơn định hướng phát triển thành công ty đầu tư, cung cấp dịch vụ và
              xuất khẩu nông sản hàng đầu Lâm Đồng.</q>
            </p>
            <Header as="h3" style={{ fontSize: "2em" }}>
              Giá trị cốt lõi
            </Header>
            <div style={{ fontSize: "1.33em", paddingLeft: "0.5em"}}>
              <div><b>TÍN</b>, Phước Sơn đặt chữ TÍN lên đầu, lấy chữ TÍN làm vũ khí cạnh tranh và bảo vệ chữ TÍN
              như bảo vệ danh dự của chính mình.</div>
              <div><b>NHÂN</b>, Phước Sơn xây dựng các mối quan hệ với khách hàng, đối tác, đồng nghiệp
              và xã hội bằng sự thiện chí, tình thân ái, tinh thần nhân văn.</div>
              <div><b>TÂM</b>, Phước Sơn đặt chữ TÂM là một trong những nền tảng quan trọng của việc kinh doanh.
              Chúng tôi coi trọng khách hàng và luôn lấy khách hàng làm trung tâm, đặt lợi ích và mong muốn
              của khách hàng lên hàng đầu.</div>
            </div>
          </Grid.Column>
          <Grid.Column floated="right" width={6}>
            <Image
              bordered
              rounded
              centered
              size="big"
              src={home_1}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Button 
              size="huge" 
              color='brown' 
              href="/about"
              style={{
                transition: "transform 0.2s",
                ":hover": {
                  transform: "scale(1.05)"
                }
              }}
              aria-label="Learn more"
            >
              <Icon name='info circle' />Giới thiệu công ty
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row >
          <Grid.Column textAlign="center" style={{ paddingBottom: "1em" }}>
            <Button 
              size="huge" 
              color='brown' 
              href="https://outlook.office365.com/owa/calendar/tlchviPhcSn@phuocson.net.vn/bookings/" 
              target="_blank"
              style={{
                transition: "transform 0.2s",
                ":hover": {
                  transform: "scale(1.05)"
                }
              }}
            >
            <Icon name='calendar' /> Đặt Lịch Hẹn
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment style={{ padding: "0em" }} vertical>
      <Grid celled="internally" columns="equal" stackable>
        <Grid.Row textAlign="center">
          <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              <q>Một doanh nghiệp tiêu biểu đi đầu trong công cuộc đổi mới phát triển của tỉnh Lâm Đồng.</q>
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              <br/>
              Đây là những nhận xét về chúng tôi.
            </p>
          </Grid.Column>
          <Grid.Column style={{ paddingBottom: "2em", paddingTop: "5em" }}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              <q>Phước Sơn tạo ra công việc ổn định cho nhân viên, và đem đến giá trị cho người tiêu dùng.</q>
            </Header>
            <div style={{ fontSize: "1.33em" }}>
              <Image alt="CEO portrait" size='tiny' circular avatar floated='right' src={image_CEO} />
              <br/>
              <div style={{ textAlign: "center", paddingLeft:"4.5em"}}>
                <b>Tổng giám đốc</b> <br/>
              </div>
              <div style={{ textAlign: "center", paddingLeft:"4.5em"}}>
                Vũ Thùy Dung
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment style={{ padding: "4em 0em" }} vertical>
      <Container text>
        <Header as="h3" style={{ fontSize: "2em" }}>
          Hoạt động kinh doanh của Phước Sơn:
        </Header>

        {/* Business Areas Section */}
        <Segment basic style={{ 
          padding: '2em 0 4em'
        }}>
          <Grid columns={2} stackable doubling>
            {businessAreas.map((area, index) => (
              <Grid.Column key={index}>
                <Segment 
                  textAlign='center' 
                  raised 
                  style={{ 
                    height: '100%',
                    padding: '2em',
                    transition: 'transform 0.2s',
                    cursor: 'pointer',
                    marginBottom: '1em',
                    '&:hover': {
                      transform: 'translateY(-5px)'
                    }
                  }}
                >
                  <Icon 
                    name={area.icon} 
                    size='huge' 
                    style={{ color: '#795548', marginBottom: '0.5em' }} 
                  />
                  <Header as='h3' style={{ 
                    color: '#5D4037',
                    fontSize: '1.5em'
                  }}>
                    {area.title}
                  </Header>
                  <p style={{ 
                    color: '#666', 
                    lineHeight: '1.6',
                    fontSize: '1.1em',
                    marginBottom: '1.5em'
                  }}>
                    {area.description}
                  </p>
                  <Button 
                    basic 
                    color='brown' 
                    as={Link} 
                    to={area.redirect_path}
                    size='small'
                    style={{ marginTop: 'auto' }}
                  >
                    Tìm hiểu thêm
                  </Button>
                </Segment>
              </Grid.Column>
            ))}
          </Grid>
        </Segment>

        <Divider
          as="h4"
          className="header"
          horizontal
          style={{ margin: "3em 0em", textTransform: "uppercase" }}
        >
          <a href="#case-studies">Về đóng góp xã hội</a>
        </Divider>
        <Header as="h4" style={{ fontSize: "1.4em" }}>
          Mang tiêu chuẩn quốc tế đến với cà phê Việt Nam
        </Header>
        <p style={{ fontSize: "1.0em" }}>
          Kể từ năm 2012, Phước Sơn đã bắt tay với hãng cà phê nổi tiếng thế giới Nestle để tạo một bước chuyển mình của
          nghành cà phê Việt Nam với chương trình cà phê 4C. Chúng tôi tổ chức tập huấn kỹ thuật, phổ biến kiến thức về
          các loại giống cà phê, và kiểm định theo kỳ. Với tất cả quy trình được ghi chép và lưu trữ, chương trình 4C tạo nên
          một sự minh bạch trong chuỗi cung ứng giúp hạt cà phê của người nông dân đạt chuẩn quốc tế và được công nhận trên thị trường
          quốc tế. Qua đó cho giá trị kinh tế và năng xuất cao hơn rất nhiều lần.
        </p>
        <Button 
          as={Link}
          to="/articles"
          style={{
            backgroundColor: '#fff',
            color: '#795548',
            marginTop: '1em'
          }}
          size="large"
        >
          Tìm hiểu thêm
        </Button>

        <ScrollToTop 
          showUnder={160}
          style={{
            backgroundColor: "rgba(0,0,0,0.3)",
            borderRadius: "50%",
            padding: "10px"
          }}
        >
          <Icon name="arrow up"/>
        </ScrollToTop>
      </Container>
    </Segment>
  </ResponsiveContainer>
);

export default HomepageLayout
