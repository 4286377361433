import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

const CardWrapper = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  background: white;
  width: 100%;
  max-width: 300px;
  margin: 1rem;

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 1rem 0;
  }

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
`;

const CardImage = styled.div`
  position: relative;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  width: 100%;
  background: #f5f5f5;
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 1rem;
  }
`;

const CardContent = styled.div`
  padding: 1rem;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  gap: 0.5rem;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 1.1rem;
  color: #333;
  flex: 1;
  line-height: 1.4;
`;

const StatusLabel = styled.div`
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 500;
  color: white;
  white-space: nowrap;
  background-color: ${props => 
    props.type === "Mới" ? "#2185d0" : 
    props.type === "Ưa chuộng" ? "#21ba45" : 
    "#db2828"};
`;

const Category = styled.div`
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const Description = styled.p`
  color: #666;
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 0.5rem 0 1rem 0;
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #e0e0e0;
  background: #f9f9f9;
`;

const PriceContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
`;

const Price = styled.span`
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
  background-color: ${props => props.discounted ? '#db2828' : '#fbbd08'};
  color: ${props => props.discounted ? 'white' : 'black'};
  font-weight: ${props => props.discounted ? '400' : '600'};
  text-decoration: ${props => props.discounted ? 'line-through' : 'none'};
`;

const CartButton = styled.button`
  background-color: #795548;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.6rem;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #5d4037;
  }

  i {
    font-size: 1.2rem;
  }
`;

const CustomCard = ({ item, onAddToCart }) => {
  const truncateDescription = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };

  return (
    <CardWrapper>
      <CardImage>
        <img src={item.image} alt={item.title} />
      </CardImage>
      <CardContent>
        <CardHeader>
          <Title>{item.title}</Title>
          <StatusLabel type={item.label}>{item.label}</StatusLabel>
        </CardHeader>
        <Category>{item.category}</Category>
        <Description>
          {truncateDescription(item.description, window.innerWidth < 768 ? 135 : 250)}
        </Description>
      </CardContent>
      <CardFooter>
        <PriceContainer>
          {item.discount_price !== null && (
            <Price discounted>
              <NumberFormat
                value={item.price}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'VND '}
                suffix={',000'}
              />
            </Price>
          )}
          <Price>
            <NumberFormat
              value={item.discount_price || item.price}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'VND '}
              suffix={',000'}
            />
          </Price>
        </PriceContainer>
        <CartButton onClick={onAddToCart}>
          <i className="cart plus icon" />
        </CartButton>
      </CardFooter>
    </CardWrapper>
  );
};

export default CustomCard; 