import React from "react";
import {
  Header,
  Embed,
  Image,
  Grid,
  Container,
  Segment,
  Dimmer,
  Loader,
  Message,
  Icon,
  Button
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import Timeline from '../components/Timeline';
import { timelineService } from "../services/timelines";
import { businessAreas } from "../data/businessAreas";
import '../styles/timeline.css';
import Hero from '../components/Hero';

import logo from '../custom_media/logo_PhuocSon.png';

class AboutLayout extends React.Component {
  state = {
    loading: false,
    error: null,
    timelineEvents: [],
    width: window.innerWidth
  };

  componentDidMount() {
    this.fetchTimelines();
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  scrollToTimeline = () => {
    const timelineSection = document.getElementById('timeline-section');
    if (timelineSection) {
      timelineSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  fetchTimelines = async () => {
    this.setState({ loading: true });
    try {
      const events = await timelineService.getTimelines();
      console.log('Fetched events:', events);
      this.setState({ 
        timelineEvents: events,
        loading: false,
        error: null
      });
    } catch (err) {
      console.error('Error fetching timelines:', err);
      this.setState({ 
        error: err.message || 'Error loading timeline data', 
        loading: false,
        timelineEvents: []
      });
    }
  };

  render() {
    const { loading, error, timelineEvents, width } = this.state;
    const isMobile = width < 768;

    return (
      <div style={{ overflow: 'hidden' }}>
        <Hero
          title="Phước Sơn™"
          subtitle="Hơn 25+ năm đồng hành cùng người nông dân trồng cà phê"
          logo={logo}
          action={{
            icon: 'history',
            text: 'Xem lịch sử công ty',
            onClick: this.scrollToTimeline
          }}
          isMobile={isMobile}
          height="40vh"
          showScroll={false}
        />

        <Container style={{ padding: isMobile ? '0 1em' : 0 }}>
          {/* Introduction Section */}
          <Grid stackable style={{ marginBottom: '3em' }}>
            <Grid.Row>
              <Grid.Column width={16} style={{ marginBottom: isMobile ? '2em' : 0 }}>
                <Header as='h2' style={{ 
                  color: '#795548', 
                  marginBottom: '1em',
                  fontSize: isMobile ? '1.8em' : '2em',
                  textAlign: isMobile ? 'center' : 'left'
                }}>
                  Về chúng tôi
                </Header>
                <p style={{ 
                  fontSize: isMobile ? '1em' : '1.1em', 
                  lineHeight: '1.8', 
                  color: '#333' 
                }}>
                  Phước Sơn là đại diện tập thể anh chị em chúng tôi trên vùng cao nguyên xa xôi đầy nắng gió. 
                  Chúng tôi lớn lên từ sự hào phóng của mẹ thiên nhiên và sự cần cù, chất phác của con người.                   Suốt hơn 20 năm qua, Phước Sơn đã luôn đồng hành cùng người nông dân trồng cà phê 
                  trong công cuộc đổi mới đời sống.
                </p>
              </Grid.Column>
              <Grid.Column width={16}>
                <Embed
                  aspectRatio='16:9'
                  active={true}
                  id='W-8hqb2rLWI'
                  placeholder={logo}
                  source='youtube'
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          {/* Business Areas */}
          <Segment basic style={{ 
            padding: isMobile ? '1em 0 2em' : '2em 0 4em'
          }}>
            <Header as='h2' textAlign='center' style={{ 
              color: '#795548',
              marginBottom: isMobile ? '1.5em' : '2em',
              fontSize: isMobile ? '1.8em' : '2em'
            }}>
              Lĩnh vực kinh doanh
            </Header>
            <Grid columns={isMobile ? 1 : 4} stackable>
              {businessAreas.map((area, index) => (
                <Grid.Column key={index} style={{ marginBottom: isMobile ? '1em' : 0 }}>
                  <Segment 
                    textAlign='center' 
                    raised 
                    style={{ 
                      height: '100%',
                      padding: isMobile ? '1.5em' : '2em',
                      transition: 'transform 0.2s',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'translateY(-5px)'
                      }
                    }}
                  >
                    <Icon 
                      name={area.icon} 
                      size={isMobile ? 'large' : 'huge'} 
                      style={{ color: '#795548', marginBottom: '0.5em' }} 
                    />
                    <Header as='h3' style={{ 
                      color: '#5D4037',
                      fontSize: isMobile ? '1.3em' : '1.5em'
                    }}>
                      {area.title}
                    </Header>
                    <p style={{ 
                      color: '#666', 
                      lineHeight: '1.6',
                      fontSize: isMobile ? '0.95em' : '1em'
                    }}>
                      {area.description}
                    </p>
                    <Button 
                      basic 
                      color='brown' 
                      as={Link} 
                      to={area.redirect_path}
                      size={isMobile ? 'small' : 'medium'}
                      style={{ marginTop: '1em' }}
                    >
                      Tìm hiểu thêm
                    </Button>
                  </Segment>
                </Grid.Column>
              ))}
            </Grid>
          </Segment>

          {/* History Section Header */}
          <div id="timeline-section">
            <Segment 
              basic 
              textAlign='center' 
              style={{ 
                padding: isMobile ? '2em 1em' : '3em 0 2em',
                background: '#F5F5F5',
                marginTop: '2em',
                borderRadius: '10px'
              }}
            >
              <Header as='h2' style={{ 
                color: '#795548',
                fontSize: isMobile ? '1.8em' : '2em',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
                <Icon name='history' />
                <Header.Content>
                  Lịch Sử Công Ty
                  <Header.Subheader style={{ 
                    color: '#666', 
                    marginTop: '0.5em',
                    fontSize: isMobile ? '0.7em' : '0.8em'
                  }}>
                    Hành trình phát triển của Phước Sơn qua các năm
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Icon 
                name='angle double down' 
                size={isMobile ? 'small' : 'large'} 
                style={{ 
                  color: '#795548',
                  marginTop: '1em',
                  animation: 'bounce 2s infinite'
                }} 
              />
            </Segment>

            {/* Timeline section with error handling */}
            {loading ? (
              <Segment basic style={{ minHeight: '300px' }}>
                <Dimmer active inverted>
                  <Loader>Đang tải...</Loader>
                </Dimmer>
              </Segment>
            ) : error ? (
              <Message
                error
                icon="warning sign"
                header="Có lỗi xảy ra"
                content={`Không thể tải dữ liệu lịch sử công ty: ${error}`}
              />
            ) : timelineEvents.length === 0 ? (
              <Message
                info
                icon="info circle"
                header="Không có dữ liệu"
                content="Chưa có dữ liệu lịch sử công ty."
              />
            ) : (
              <Timeline events={timelineEvents} />
            )}
          </div>
        </Container>
      </div>
    );
  }
}

export default AboutLayout;
