import { Directus } from '@directus/sdk';

export const DIRECTUS_URL = process.env.REACT_APP_BACKEND_URL || 'https://cms.phuocson.net.vn';
const STATIC_TOKEN = process.env.REACT_APP_DIRECTUS_TOKEN;

if (!STATIC_TOKEN) {
  console.error('REACT_APP_DIRECTUS_TOKEN is not set in environment variables');
}

// Initialize Directus client with static token
export const directusClient = new Directus(DIRECTUS_URL, {
  auth: {
    staticToken: STATIC_TOKEN
  }
});

// Wrapper for API calls with error handling
export const apiCall = async (fn) => {
  try {
    const response = await fn(directusClient);
    return response;
  } catch (error) {
    console.error('API call failed:', {
      message: error.message,
      status: error.status,
      code: error.code
    });
    throw new Error(error.message || 'Failed to communicate with the API');
  }
};
