import { directusClient, apiCall, DIRECTUS_URL } from '../config/directus';

export const postService = {
  getPosts: async ({ page = 1, limit = 5, category = null, type = null }) => {
    const offset = (page - 1) * limit;
    
    const filter = {
      status: { _eq: 'published' }
    };
    
    if (category) {
      filter.category = { _eq: category };
    }
    
    if (type) {
      filter.type = { _eq: type };
    }

    try {
      const response = await directusClient.items('posts').readByQuery({
        fields: [
          'id', 
          'title', 
          'summary', 
          'content', 
          'image', 
          'type', 
          'date_published',
          'category.id',
          'category.title',
          'slug'
        ],
        filter,
        sort: ['-date_published'],
        limit,
        offset,
        meta: 'filter_count'
      });

      const transformedData = response.data.map(item => ({
        ...item,
        image: item.image ? `${DIRECTUS_URL}/assets/${item.image}` : null,
        category: item.category?.title
      }));

      return {
        data: transformedData,
        totalCount: response.meta.filter_count,
        page,
        totalPages: Math.ceil(response.meta.filter_count / limit)
      };
    } catch (error) {
      console.error('Error fetching posts:', error);
      throw error;
    }
  },

  getPostById: async (id) => {
    try {
      const response = await directusClient.items('posts').readOne(id, {
        fields: [
          'id', 
          'title', 
          'summary', 
          'content', 
          'image', 
          'type', 
          'date_published',
          'category.id',
          'category.title',
          'slug'
        ]
      });
      
      return response ? {
        ...response,
        image: response.image ? `${DIRECTUS_URL}/assets/${response.image}` : null,
        category: response.category?.title
      } : null;
    } catch (error) {
      console.error('Error fetching post:', error);
      throw error;
    }
  },

  getPostBySlug: async (slug) => {
    try {
      const response = await directusClient.items('posts').readByQuery({
        filter: {
          slug: { _eq: slug },
          status: { _eq: 'published' }
        },
        fields: [
          'id', 
          'title', 
          'summary', 
          'content', 
          'image', 
          'type', 
          'date_published',
          'category.id',
          'category.title',
          'slug',
          'author.id',
          'author.name',
          'author.job_title',
          'author.image'
        ],
        limit: 1
      });
      
      const post = response.data[0];
      return post ? {
        ...post,
        image: post.image ? `${DIRECTUS_URL}/assets/${post.image}` : null,
        category: post.category?.title,
        author: post.author ? {
          ...post.author,
          image: post.author.image ? `${DIRECTUS_URL}/assets/${post.author.image}` : null
        } : null
      } : null;
    } catch (error) {
      console.error('Error fetching post by slug:', error);
      throw error;
    }
  },

  getCategories: async () => {
    try {
      const response = await directusClient.items('categories').readByQuery({
        fields: ['id', 'title', 'slug']
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw error;
    }
  },

  getTypes: async () => {
    try {
      const response = await directusClient.items('types').readByQuery({
        fields: ['id', 'name', 'slug']
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching types:', error);
      throw error;
    }
  }
}; 