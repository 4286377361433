import React from "react";
import {
  Container,
  Dimmer,
  Header,
  Loader,
  Message,
  Segment,
  Icon,
  Item,
  Label,
  Button,
  Pagination,
  Dropdown,
  Grid,
  Menu
} from "semantic-ui-react";
import { postService } from "../services/posts";
import { DIRECTUS_URL } from "../config/directus";
import Hero from '../components/Hero';

class NewsfeedList extends React.Component {
  state = {
    loading: false,
    error: null,
    data: [],
    width: window.innerWidth,
    currentPage: 1,
    totalPages: 1,
    selectedType: null,
    itemsPerPage: 5,
    types: ['Tin tức', 'Thông báo', 'Sự kiện', 'Blog']
  };

  componentDidMount() {
    this.fetchPosts();
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  fetchPosts = async () => {
    const { currentPage, itemsPerPage, selectedType } = this.state;
    
    this.setState({ loading: true });
    try {
      const result = await postService.getPosts({
        page: currentPage,
        limit: itemsPerPage,
        type: selectedType
      });
      
      this.setState({ 
        data: result.data,
        totalPages: result.totalPages,
        loading: false 
      });
    } catch (err) {
      this.setState({ 
        error: err, 
        loading: false 
      });
    }
  };

  handlePageChange = (e, { activePage }) => {
    this.setState({ currentPage: activePage }, this.fetchPosts);
  };

  handleTypeChange = (type) => {
    this.setState({ 
      selectedType: type === this.state.selectedType ? null : type,
      currentPage: 1
    }, this.fetchPosts);
  };

  render() {
    const { 
      data, 
      error, 
      loading, 
      width,
      currentPage,
      totalPages,
      types,
      selectedType
    } = this.state;
    
    const isMobile = width < 768;

    return (
      <Container style={{ 
        paddingBottom: '4em'
      }}>
        <Hero
          title="Bảng tin"
          subtitle="Cập nhật thông báo, tin tức, sự kiện, và chia sẻ mới nhất từ Phước Sơn™"
          height="25vh"
          showScroll={true}
          isMobile={isMobile}
        />

        {error && (
          <Message
            error
            icon="warning sign"
            header="Có lỗi xảy ra"
            content="Không thể tải bài viết. Vui lòng thử lại sau."
          />
        )}

        <Segment basic style={{ 
          marginBottom: '2em',
          overflowX: 'auto',
          padding: isMobile ? '0.5em 0' : undefined
        }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.5em',
            justifyContent: isMobile ? 'flex-start' : 'center'
          }}>
            {types.map(type => (
              <Label
                key={type}
                as='a'
                color={selectedType === type ? 'brown' : undefined}
                onClick={() => this.handleTypeChange(type)}
                style={{ 
                  marginBottom: '0.5em',
                  fontSize: isMobile ? '0.85em' : '1em',
                  padding: isMobile ? '0.5em 0.8em' : '0.5em 1em'
                }}
              >
                {type}
                {selectedType === type && <Icon name='delete' onClick={() => this.handleTypeChange(null)} />}
              </Label>
            ))}
          </div>
        </Segment>

        {loading ? (
          <Segment basic style={{ minHeight: '300px' }}>
            <Dimmer active inverted>
              <Loader>Đang tải...</Loader>
            </Dimmer>
          </Segment>
        ) : (
          <>
            <Item.Group divided style={{ margin: '2em 0' }}>
              {data.map(item => (
                <Item 
                  key={item.id} 
                  style={{ 
                    padding: isMobile ? '1.5em 0' : '2em 0',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: isMobile ? 'stretch' : 'flex-start',
                    gap: isMobile ? '1em' : '2em'
                  }}
                >
                  {item.image && (
                    <div style={{
                      flex: isMobile ? '0 0 auto' : '0 0 320px',
                      height: isMobile ? '200px' : '200px',
                      borderRadius: '8px',
                      overflow: 'hidden',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                    }}>
                      <img 
                        src={item.image}
                        alt={item.title}
                        style={{ 
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          transition: 'transform 0.3s ease'
                        }}
                      />
                    </div>
                  )}
                  <Item.Content style={{ 
                    flex: 1, 
                    minWidth: 0,
                    marginTop: isMobile ? '0.5em' : 0
                  }}>
                    <Item.Header 
                      as="a"
                      onClick={() => this.props.history.push(`/articles/${item.slug}`)}
                      style={{ 
                        fontSize: isMobile ? '1.3em' : '1.5em', 
                        fontWeight: '500',
                        marginBottom: '0.8em',
                        color: '#2c3e50',
                        cursor: 'pointer',
                        display: 'block',
                        lineHeight: '1.3'
                      }}
                    >
                      {item.title}
                    </Item.Header>
                    
                    <Item.Meta style={{ 
                      marginBottom: '1em',
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      gap: isMobile ? '0.7em' : '1em',
                      color: '#7f8c8d',
                      fontSize: isMobile ? '0.85em' : '0.95em'
                    }}>
                      <span style={{ display: 'flex', alignItems: 'center', gap: '0.4em' }}>
                        <Icon name='calendar' />
                        {new Date(item.date_published).toLocaleDateString('vi-VN', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </span>
                      {item.type && (
                        <Label 
                          size='tiny' 
                          color='brown'
                          style={{
                            margin: 0,
                            padding: '0.5em 1em',
                            fontSize: isMobile ? '0.85em' : '0.9em'
                          }}
                        >
                          {item.type}
                        </Label>
                      )}
                      {item.category && (
                        <span style={{ display: 'flex', alignItems: 'center', gap: '0.4em' }}>
                          <Icon name='folder outline' />
                          {item.category}
                        </span>
                      )}
                    </Item.Meta>

                    <Item.Description style={{ 
                      marginBottom: '1.5em',
                      lineHeight: '1.6',
                      color: '#666',
                      fontSize: isMobile ? '1em' : '1.1em',
                      display: '-webkit-box',
                      WebkitLineClamp: '3',
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                      {item.summary}
                    </Item.Description>

                    <Item.Extra>
                      <Button
                        primary
                        floated={isMobile ? undefined : 'right'}
                        fluid={isMobile}
                        onClick={() => this.props.history.push(`/articles/${item.slug}`)}
                        style={{ 
                          background: '#795548',
                          padding: isMobile ? '0.7em 1.2em' : '0.8em 1.5em',
                          borderRadius: '4px',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                          transition: 'all 0.2s ease',
                          marginTop: isMobile ? '1em' : undefined,
                          fontSize: isMobile ? '0.9em' : '1em'
                        }}
                      >
                        Xem thêm
                        <Icon name='right chevron' style={{ marginLeft: '0.5em' }} />
                      </Button>
                    </Item.Extra>
                  </Item.Content>
                </Item>
              ))}
            </Item.Group>

            {totalPages > 1 && (
              <Container textAlign='center' className="pagination-container" style={{
                marginTop: isMobile ? '2em' : '3em'
              }}>
                <Pagination
                  activePage={currentPage}
                  totalPages={totalPages}
                  onPageChange={this.handlePageChange}
                  pointing
                  secondary
                  size={isMobile ? 'small' : 'large'}
                  firstItem={isMobile ? null : { content: <Icon name='angle double left' />, icon: true }}
                  lastItem={isMobile ? null : { content: <Icon name='angle double right' />, icon: true }}
                  prevItem={{ content: <Icon name='angle left' />, icon: true }}
                  nextItem={{ content: <Icon name='angle right' />, icon: true }}
                  ellipsisItem={isMobile ? null : { content: <Icon name='ellipsis horizontal' />, icon: true }}
                />
                <div style={{ 
                  marginTop: '1em', 
                  color: '#666',
                  fontSize: isMobile ? '0.9em' : '1em' 
                }}>
                  Trang {currentPage} / {totalPages}
                </div>
              </Container>
            )}
          </>
        )}
      </Container>
    );
  }
}

export default NewsfeedList;
