export const businessAreas = [
  {
    icon: 'suitcase',
    title: 'Đầu tư',
    description: 'Đầu tư đa nghành vào dự án kinh doanh & xã hội. Hãy liên hệ với chúng tôi!',
    redirect_path: '/about'
  },
  {
    icon: 'coffee',
    title: 'Cà phê',
    description: 'Chế biến và sản xuất cà phê nhân xanh thành phẩm trong nước và xuất khẩu',
    redirect_path: '/about'
  },
  {
    icon: 'shopping basket',
    title: 'Đặc sản',
    description: 'Kinh doanh các mặt hàng sản phẩm nổi bật của Lâm Đồng',
    redirect_path: '/products'
  },
  {
    icon: 'hotel',
    title: 'Khách sạn',
    description: 'Kinh doanh dịch vụ lưu trú khách sạn Phước Sơn',
    redirect_path: '/hotel'
  }
]; 