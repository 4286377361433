import React from 'react';
import { Segment, Container, Header, Icon, Image, Button } from 'semantic-ui-react';
import styled from 'styled-components';

export const ScrollIndicator = styled.div`
  position: absolute;
  bottom: ${props => props.$bottom || '1rem'};
  left: 50%;
  transform: translateX(-50%);
  animation: bounce 2s infinite;
  cursor: pointer;
  opacity: ${props => props.$opacity || '0.7'};
  z-index: ${props => props.$zIndex || '1'};

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% { transform: translateX(-50%) translateY(0); }
    40% { transform: translateX(-50%) translateY(-10px); }
    60% { transform: translateX(-50%) translateY(-5px); }
  }
`;

const Hero = ({
  title,
  subtitle,
  backgroundImage,
  backgroundOverlay = true,
  icon,
  logo,
  height = '25vh',
  minHeight = '200px',
  showScroll = true,
  gradient,
  action,
  isMobile = false,
  scrollTo
}) => {
  const defaultGradient = backgroundImage 
    ? 'linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7))'
    : 'linear-gradient(135deg, #795548 0%, #5D4037 100%)';

  const handleScroll = () => {
    window.scrollTo({
      top: scrollTo || window.innerHeight * 0.25,
      behavior: 'smooth'
    });
  };

  return (
    <Segment
      basic
      textAlign='center'
      style={{
        background: backgroundImage 
          ? `${backgroundOverlay ? defaultGradient + ',' : ''} url(${backgroundImage})`
          : gradient || defaultGradient,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: isMobile ? '2em 1em' : '4em 0',
        margin: 0,
        height,
        minHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        borderRadius: '15px',
        marginBottom: '1rem'
      }}
    >
      <Container text>
        {logo && (
          <Image 
            src={logo} 
            size='small' 
            centered 
            style={{ marginBottom: '2em' }}
          />
        )}
        <Header 
          as='h2' 
          inverted
          style={{
            fontSize: isMobile ? '1.8em' : '2em',
            marginBottom: subtitle ? '0.5em' : '0'
          }}
        >
          {icon && <Icon name={icon} />}
          <Header.Content>
            {title}
            {subtitle && (
              <Header.Subheader style={{ 
                color: '#E0E0E0',
                fontSize: isMobile ? '0.7em' : '0.8em',
                margin: '0.5em 0'
              }}>
                {subtitle}
              </Header.Subheader>
            )}
          </Header.Content>
        </Header>

        {action && (
          <Button
            size={isMobile ? 'small' : 'large'}
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              color: '#795548',
              marginTop: '1em'
            }}
            onClick={action.onClick}
          >
            {action.icon && <Icon name={action.icon} />}
            {action.text}
          </Button>
        )}
      </Container>

      {showScroll && (
        <ScrollIndicator onClick={handleScroll}>
          <Icon 
            name='chevron down' 
            inverted
            size='large'
          />
        </ScrollIndicator>
      )}
    </Segment>
  );
};

export default Hero; 