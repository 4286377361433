import React, { useState, useEffect } from "react";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Icon,
  Transition
} from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../store/actions/auth";
import { fetchCart } from "../store/actions/cart";
import { ScrollIndicator } from '../components/Hero';

import logo from '../custom_media/logo_PhuocSon.png';
import home_main from '../custom_media/home_main.JPG';
import program_4c_logo from '../custom_media/others/program_4c.png';

const CustomLayout = ({ authenticated, cart, loading, location, history, logout, fetchCart, children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [visible, setVisible] = useState(true);
  const isMobile = width < 768;

  useEffect(() => {
    // Temporarily disable cart fetching since auth is disabled
    // fetchCart();
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [fetchCart]);

  const renderNavigation = () => (
    <Menu
      fixed='top'
      inverted
      size='small'
      style={{
        background: 'rgba(0, 0, 0, 0.95)',
        border: 'none',
        zIndex: 999
      }}
    >
      <Container>
        <Link to="/">
          <Image 
            style={{
              width: '35px',
              height: 'auto',
              margin: '0.5em',
              transition: 'transform 0.2s',
              ':hover': {
                transform: 'scale(1.1)'
              }
            }}
            src={logo}
            alt="Phuoc Son Logo"
          />
        </Link>

        {!isMobile && (
          <>
            <Dropdown 
              text='Phước Sơn' 
              className='link item'
              style={{ fontWeight: 'bold' }}
            >
              <Dropdown.Menu style={{ 
                backgroundColor: "rgba(0, 0, 0, 0.95)",
                border: '1px solid rgba(255, 255, 255, 0.1)',
                minWidth: '200px'
              }}>
                <Dropdown.Item style={{ padding: 0 }}>
                  <Link to="/about" style={{ 
                    color: "white",
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 16px',
                    width: '100%'
                  }}>
                    <Icon name='info circle' style={{ marginRight: '8px' }}/>
                    Giới thiệu
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item style={{ padding: 0 }}>
                  <Link to="/sustainability" style={{ 
                    color: "white",
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 16px',
                    width: '100%'
                  }}>
                    <Icon name='leaf' style={{ marginRight: '8px' }}/>
                    Phát triển bền vững
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item style={{ padding: 0 }}>
                  <Link to="/career" style={{ 
                    color: "white",
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 16px',
                    width: '100%'
                  }}>
                    <Icon name='briefcase' style={{ marginRight: '8px' }}/>
                    Tuyển dụng
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Link to="/articles">
              <Menu.Item header style={{ fontWeight: 'bold' }}>Bảng Tin</Menu.Item>
            </Link>
            <Link to="/products">
              <Menu.Item header style={{ fontWeight: 'bold' }}>Cửa Hàng</Menu.Item>
            </Link>
            <Link to="/hotel">
              <Menu.Item header style={{ fontWeight: 'bold' }}>Khách Sạn</Menu.Item>
            </Link>
          </>
        )}

        {isMobile && (
          <Menu.Menu position='right'>
            <Dropdown item icon='bars'>
              <Dropdown.Menu style={{ 
                backgroundColor: "rgba(0, 0, 0, 0.95)",
                border: '1px solid rgba(255, 255, 255, 0.1)',
                minWidth: '200px'
              }}>
                <Dropdown.Item style={{ padding: 0 }}>
                  <Link to="/about" style={{ 
                    color: "white",
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 16px',
                    width: '100%'
                  }}>
                    <Icon name='info circle' style={{ marginRight: '8px' }}/>
                    Giới thiệu
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item style={{ padding: 0 }}>
                  <Link to="/articles" style={{ 
                    color: "white",
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 16px',
                    width: '100%'
                  }}>
                    <Icon name='tv' style={{ marginRight: '8px' }}/>
                    Bảng Tin
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item style={{ padding: 0 }}>
                  <Link to="/products" style={{ 
                    color: "white",
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 16px',
                    width: '100%'
                  }}>
                    <Icon name='shopping bag' style={{ marginRight: '8px' }}/>
                    Cửa Hàng
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item style={{ padding: 0 }}>
                  <Link to="/hotel" style={{ 
                    color: "white",
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 16px',
                    width: '100%'
                  }}>
                    <Icon name='hotel' style={{ marginRight: '8px' }}/>
                    Khách Sạn
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item style={{ padding: 0 }}>
                  <Link to="/sustainability" style={{ 
                    color: "white",
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 16px',
                    width: '100%'
                  }}>
                    <Icon name='leaf' style={{ marginRight: '8px' }}/>
                    Phát triển bền vững
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item style={{ padding: 0 }}>
                  <Link to="/career" style={{ 
                    color: "white",
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 16px',
                    width: '100%'
                  }}>
                    <Icon name='briefcase' style={{ marginRight: '8px' }}/>
                    Tuyển dụng
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        )}

        {authenticated ? (
          <Menu.Menu position='right'>
            <Link to="/cart">
              <Menu.Item header>
                <Icon name='cart' />
                {cart !== null ? `${cart.order_items.length}` : "0"}
              </Menu.Item>
            </Link>
            <Dropdown
              item
              text={`${authenticated.username}`}
            >
              <Dropdown.Menu style={{ 
                backgroundColor: "rgba(0, 0, 0, 0.95)",
                border: '1px solid rgba(255, 255, 255, 0.1)'
              }}>
                <Dropdown.Item style={{ padding: 0 }}>
                  <Link to="/profile" style={{ 
                    color: "white",
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 16px',
                    width: '100%'
                  }}>
                    <Icon name='user' style={{ marginRight: '8px' }}/>
                    Hồ Sơ
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item 
                  onClick={logout}
                  style={{ 
                    color: "white",
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px 16px'
                  }}
                >
                  <Icon name='sign out' style={{ marginRight: '8px' }}/>
                  Đăng Xuất
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        ) : (
          null
        )}
      </Container>
    </Menu>
  );

  const renderHero = () => (
    <Segment
      inverted
      textAlign='center'
      style={{
        minHeight: isMobile ? '50vh' : '80vh',
        padding: '1em 0em',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${home_main})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: isMobile ? 'scroll' : 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
      }}
      vertical
    >
      <Container text>
        <Header
          as='h1'
          content='Phước Sơn'
          inverted
          style={{
            fontSize: isMobile ? '2.5em' : '4em',
            fontWeight: 'bold',
            marginBottom: 0,
            textShadow: '2px 2px 4px rgba(0,0,0,0.5)'
          }}
        />
        <Header
          as='h2'
          content='Vì Một Việt Nam Tươi Đẹp Hơn'
          inverted
          style={{
            fontSize: isMobile ? '1.5em' : '1.7em',
            fontWeight: 'normal',
            marginTop: '0.5em',
            textShadow: '1px 1px 2px rgba(0,0,0,0.5)'
          }}
        />
        
        <ScrollIndicator 
          $bottom="2rem"
          $opacity="0.9"
          $zIndex="10"
          onClick={() => {
            window.scrollTo({
              top: window.innerHeight,
              behavior: 'smooth'
            });
          }}
        >
          <Icon 
            name='angle double down' 
            size='large' 
            inverted 
          />
        </ScrollIndicator>
      </Container>
    </Segment>
  );

  const renderFooter = () => (
    <Segment
      inverted
      vertical
      style={{ 
        margin: "5em 0em 0em",
        padding: "5em 0em",
        background: 'linear-gradient(to bottom, #1b1c1d, #000000)'
      }}
    >
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={6}>
              <Header inverted as="h3" content="Liên hệ PHƯỚC SƠN™" textAlign="center" />
              <Divider />
              <List relaxed inverted>
                <List.Item>
                  <List.Icon name='building' />
                  <List.Content>
                    <List.Header>Công ty Phước Sơn</List.Header>
                    <List.Description>
                      <a href="https://goo.gl/maps/6xA9q2Yx34T4q7jp9" 
                         target="_blank" 
                         rel="noopener noreferrer"
                         style={{ color: '#ffffff80' }}>
                        <Icon name="map marker"/> Gia Lâm, Lâm Hà, Lâm Đồng
                      </a>
                    </List.Description>
                    <List.Description style={{ marginTop: '0.5em' }}>
                      <Icon name="clock outline"/> 8:00 - 18:00 (Hàng ngày)
                    </List.Description>
                    <List.Description>
                      <Icon name="phone"/> 0263.3852.364
                    </List.Description>
                    <List.Description>
                      <Icon name="mail"/> vanphong@phuocson.net.vn
                    </List.Description>
                  </List.Content>
                </List.Item>

                <List.Item style={{ marginTop: '2em' }}>
                  <List.Icon name='hotel' />
                  <List.Content>
                    <List.Header>Khách sạn Phước Sơn</List.Header>
                    <List.Description>
                      <a href="https://goo.gl/maps/25p4sMHvK6av4K4JA" 
                         target="_blank" 
                         rel="noopener noreferrer"
                         style={{ color: '#ffffff80' }}>
                        <Icon name="map marker"/> 6G, Nam Kỳ Khởi Nghĩa, P.1, Đà Lạt
                      </a>
                    </List.Description>
                    <List.Description style={{ marginTop: '0.5em' }}>
                      <Icon name="clock outline"/> 6:00 - 22:00 (Hàng ngày)
                    </List.Description>
                    <List.Description>
                      <Icon name="phone"/> 0964.500.289
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>

            <Grid.Column width={5}>
              <Header inverted as="h3" content="Chứng nhận" textAlign="center" />
              <Divider />
              <List.Item as="a" href="https://www.4c-services.org/" target="_blank">
                <Image centered size="tiny" src={program_4c_logo} />
              </List.Item>
            </Grid.Column>

            <Grid.Column width={5}>
              <Header inverted as="h3" content="Liên kết nhanh" textAlign="center" />
              <Divider />
              <List link inverted style={{ textAlign: 'center' }}>
                <List.Item as="a" href="/" style={{ justifyContent: 'center' }}>
                  Trang chủ
                </List.Item>
                <List.Item as="a" href="/about" style={{ justifyContent: 'center' }}>
                  Giới thiệu
                </List.Item>
                <List.Item as="a" href="/sustainability" style={{ justifyContent: 'center' }}>
                  Phát triển bền vững
                </List.Item>
                <List.Item as="a" href="/career" style={{ justifyContent: 'center' }}>
                  Tuyển dụng
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider inverted section />
        
        <Grid centered>
          <Grid.Row>
            <Image centered size="tiny" src={logo} />
          </Grid.Row>
          <Grid.Row>
            <List horizontal inverted divided link>
              <List.Item as="a" href="#">Liên Hệ</List.Item>
              <List.Item>Copyright © 2025 <b>Phước Sơn™</b></List.Item>
              <List.Item as="a" href="#">Privacy Policy</List.Item>
            </List>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );

  return (
    <div>
      {renderNavigation()}
      {location.pathname === "/" && renderHero()}
      
      <Container style={{ 
        margin: "2em 0em 0em", 
        padding: "2em 0em",
        minHeight: '50vh'
      }}>
        {children}
      </Container>

      {renderFooter()}
    </div>
  );
};

const mapStateToProps = state => ({
  authenticated: state.auth.token !== null,
  cart: state.cart.shoppingCart,
  loading: state.cart.loading
});

const mapDispatchToProps = {
  logout,
  fetchCart
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomLayout)
);
