import React, { useState, useEffect } from "react";
import { 
  Container, 
  Card,
  Header, 
  Message, 
  Icon,
  Button, 
  Image, 
  Modal, 
  Grid,
  Segment,
  Label,
  Dimmer,
  Loader,
  Transition
} from 'semantic-ui-react';
import { jobService } from '../services/jobs';
import logo from '../custom_media/logo_PhuocSon.png';

function CareerLayout() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    fetchJobs();
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = width < 768;

  const fetchJobs = async () => {
    try {
      const data = await jobService.getJobs();
      setJobs(data);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const cardStyle = {
    boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
    transition: 'all 0.3s ease',
    borderRadius: '12px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  };

  const hoverCardStyle = {
    ...cardStyle,
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 20px rgba(0,0,0,0.15)'
  };

  if (loading) {
    return (
      <Segment basic style={{ height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Dimmer active inverted>
          <div style={{ textAlign: 'center' }}>
            <Loader size='large'>Đang tải...</Loader>
            <div style={{ marginTop: '1em', color: '#795548' }}>Đang tải danh sách việc làm...</div>
          </div>
        </Dimmer>
      </Segment>
    );
  }

  if (error) {
    return (
      <Container style={{ marginTop: '4em', maxWidth: '600px' }}>
        <Message 
          negative
          icon='warning sign'
          header='Có lỗi xảy ra'
          content='Không thể tải danh sách việc làm. Vui lòng thử lại sau.'
          style={{ 
            borderRadius: '12px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
          }}
        />
      </Container>
    );
  }

  return (
    <Container fluid style={{ 
      padding: isMobile ? '2em 1em' : '3em 0',
      background: 'linear-gradient(180deg, #f8f9fa 0%, #ffffff 100%)'
    }}>
      {/* Hero Section */}
      <Segment
        basic
        style={{
          background: 'linear-gradient(135deg, #795548 0%, #5D4037 100%)',
          padding: isMobile ? '2em 1em' : '4em 0',
          marginTop: '-3em',
          borderRadius: 0,
          position: 'relative'
        }}
      >
        <Container text textAlign='center'>
            <Image 
              src={logo} 
              size='small' 
              centered 
              style={{ marginBottom: '2em' }}
            />
          <Header as='h2' inverted>
            Cơ Hội Nghề Nghiệp
            <Header.Subheader style={{ 
              color: '#E0E0E0',
              fontSize: isMobile ? '1em' : '1.2em',
              margin: '1em 0',
              fontWeight: '400'
            }}>
              Hãy cùng Phước Sơn xây dựng tương lai của bạn
            </Header.Subheader>
          </Header>
        </Container>
      </Segment>

      <Container>
        {/* Job Listings */}
        <div className="job-listings">
          <Grid stackable columns={isMobile ? 1 : 2} style={{ marginTop: '2em' }}>
            <Grid.Row>
              {jobs.map(job => (
                <Grid.Column key={job.id} style={{ marginBottom: '2em', display: 'flex' }}>
                  <Transition animation='fade up' duration={500} style={{ width: '100%' }}>
                    <Card
                      fluid
                      style={{
                        boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                        transition: 'all 0.3s ease',
                        borderRadius: '12px',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = 'translateY(-5px)';
                        e.currentTarget.style.boxShadow = '0 12px 20px rgba(0,0,0,0.15)';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = 'translateY(0)';
                        e.currentTarget.style.boxShadow = '0 8px 16px rgba(0,0,0,0.1)';
                      }}
                    >
                      <Card.Content style={{ flexGrow: 1 }}>
                        <Card.Header style={{ 
                          color: '#795548',
                          fontSize: '1.4em',
                          marginBottom: '1em',
                          lineHeight: '1.4'
                        }}>
                          {job.job_title}
                        </Card.Header>
                        <Card.Meta style={{ marginBottom: '1em' }}>
                          <Label color='brown' style={{ marginRight: '0.5em', padding: '0.5em 1em' }}>
                            <Icon name='clock outline' />
                            {new Date(job.date_created).toLocaleDateString('vi-VN')}
                          </Label>
                          <Label basic style={{ padding: '0.5em 1em' }}>
                            <Icon name='map marker alternate' />
                            {job.location || 'Đà Lạt'}
                          </Label>
                        </Card.Meta>
                        <Card.Description style={{ 
                          margin: '1em 0',
                          color: '#666',
                          lineHeight: '1.6',
                          fontSize: '1.1em'
                        }}>
                          {job.job_summary}
                        </Card.Description>
                      </Card.Content>
                      <Card.Content extra style={{ 
                        background: '#f8f9fa', 
                        borderTop: '1px solid #eee',
                        padding: '1em',
                        marginTop: 'auto'
                      }}>
                        <Button
                          fluid
                          color='brown'
                          size='large'
                          onClick={() => {
                            setSelectedJob(job);
                            setModalOpen(true);
                          }}
                          style={{
                            borderRadius: '8px',
                            fontWeight: '600'
                          }}
                        >
                          <Icon name='info circle' />
                          Xem chi tiết
                        </Button>
                      </Card.Content>
                    </Card>
                  </Transition>
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>

          {/* Job Detail Modal */}
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            size='large'
            style={{ 
              maxHeight: '90vh',
              borderRadius: '12px',
              marginTop: '50px'
            }}
          >
            {selectedJob && (
              <>
                <Modal.Header style={{ 
                  background: 'linear-gradient(135deg, #795548 0%, #8D6E63 100%)',
                  color: 'white',
                  borderRadius: '12px 12px 0 0',
                  padding: '2em'
                }}>
                  <Grid verticalAlign='middle'>
                    <Grid.Column width={3}>
                      <Image 
                        src={logo} 
                        size='small'  
                        style={{ marginBottom: '2em' }}
                      />
                    </Grid.Column>
                    <Grid.Column width={13}>
                      <Header as='h2' inverted style={{ margin: 0, fontSize: '1.8em' }}>
                        {selectedJob.job_title}
                        <Header.Subheader style={{ 
                          color: '#E0E0E0', 
                          marginTop: '0.8em',
                          fontSize: '0.7em',
                          opacity: 0.9
                        }}>
                          Công ty Phước Sơn
                          <div style={{ marginTop: '0.5em' }}>
                            <Icon name='map marker alternate' />
                            {selectedJob.location}
                          </div>
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                  </Grid>
                </Modal.Header>
                <Modal.Content style={{ padding: '2em' }}>
                  <div 
                    dangerouslySetInnerHTML={{ __html: selectedJob.job_description }}
                    style={{
                      fontSize: '1.1em',
                      lineHeight: '1.8',
                      color: '#333',
                      '& h1, & h2, & h3': {
                        color: '#795548',
                        marginTop: '1.8em',
                        fontWeight: '600'
                      },
                      '& ul': {
                        paddingLeft: '1.8em',
                        marginBottom: '1.8em'
                      },
                      '& li': {
                        marginBottom: '0.8em'
                      }
                    }}
                  />
                </Modal.Content>
                <Modal.Actions style={{ 
                  background: '#f8f9fa',
                  padding: '1.5em',
                  borderTop: '1px solid #eee',
                  borderRadius: '0 0 12px 12px'
                }}>
                  <Button 
                    basic 
                    color='grey' 
                    onClick={() => setModalOpen(false)}
                    style={{ marginRight: '1em' }}
                  >
                    <Icon name='close' /> Đóng
                  </Button>
                  <Button 
                    color='brown'
                    size='large'
                    onClick={() => window.open('https://forms.office.com/r/mAT7nV4YQJ', '_blank')}
                    style={{
                      borderRadius: '8px',
                      fontWeight: '600'
                    }}
                  >
                    <Icon name='send' /> Ứng tuyển ngay
                  </Button>
                </Modal.Actions>
              </>
            )}
          </Modal>

          {/* Contact Section */}
          <Segment 
            basic 
            textAlign='center' 
            style={{ 
              marginTop: '5em',
              padding: isMobile ? '3em 1.5em' : '4em',
              background: 'linear-gradient(135deg, #795548 0%, #8D6E63 100%)',
              borderRadius: '16px',
              maxWidth: '800px',
              margin: '5em auto',
              color: 'white',
              boxShadow: '0 8px 16px rgba(121, 85, 72, 0.2)'
            }}
          >
            <Header as='h3' inverted>
              <Icon name='mail' style={{ marginBottom: '0.5em' }} />
              <Header.Content style={{ fontSize: '1.4em' }}>
                Không tìm thấy vị trí phù hợp?
                <Header.Subheader style={{ 
                  color: '#E0E0E0', 
                  marginTop: '1em',
                  fontSize: '0.8em',
                  lineHeight: '1.6',
                  fontWeight: '400'
                }}>
                  Hãy gửi thông tin của bạn đến chúng tôi để được thông báo về các cơ hội mới nhất
                </Header.Subheader>
              </Header.Content>
            </Header>
            <Button 
              size='large'
              style={{ 
                marginTop: '2em',
                background: 'white',
                color: '#795548',
                padding: '1em 2em',
                borderRadius: '8px',
                fontWeight: '600',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
              }}
              onClick={() => window.open('https://forms.office.com/r/mAT7nV4YQJ', '_blank')}
            >
              <Icon name='paper plane' />
              Ứng tuyển ngay
            </Button>
          </Segment>
        </div>
      </Container>
    </Container>
  );
}

export default CareerLayout;
