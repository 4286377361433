import React from "react";
import {
  Divider,
  Header,
  Grid,
  Container,
  Segment,
  Transition,
  Icon,
  Card,
  Button,
  Statistic
} from "semantic-ui-react";

import green_sustainability_1 from '../custom_media/sustainability/green_sustainability.jpg';

class SustainabilityLayout extends React.Component {
  state = {
    width: window.innerWidth,
    visible: true,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width, visible } = this.state;
    const isMobile = width < 768;

    const sustainabilityPillars = [
      {
        icon: 'leaf',
        title: 'Môi trường',
        description: 'Cam kết giảm thiểu tác động môi trường thông qua các hoạt động kinh doanh bền vững và thân thiện với môi trường.'
      },
      {
        icon: 'users',
        title: 'Xã hội',
        description: 'Đóng góp tích cực vào cộng đồng địa phương thông qua các chương trình phát triển và hỗ trợ cộng đồng.'
      },
      {
        icon: 'building',
        title: 'Quản trị',
        description: 'Đảm bảo tính minh bạch và trách nhiệm trong mọi hoạt động kinh doanh.'
      }
    ];

    const impactStats = [
      { value: '30%', label: 'Giảm phát thải CO2' },
      { value: '1,000+', label: 'Cây xanh được trồng' },
      { value: '10,000+', label: 'Người dân được hỗ trợ' },
      { value: '50%', label: 'Năng lượng tái tạo' }
    ];

    return (
      <div style={{ overflow: 'hidden' }}>
        {/* Hero Section */}
        <Segment
          inverted
          textAlign='center'
          style={{
            minHeight: isMobile ? '400px' : '600px',
            padding: '1em 0em',
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${green_sustainability_1})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 0
          }}
          vertical
        >
          <Container text>
            <Header
              as='h1'
              content='Phát triển bền vững'
              inverted
              style={{
                fontSize: isMobile ? '2.5em' : '4em',
                fontWeight: 'bold',
                marginBottom: '0.5em',
              }}
            />
            <Header
              as='h2'
              content='Vì một tương lai xanh và bền vững'
              inverted
              style={{
                fontSize: isMobile ? '1.2em' : '1.7em',
                fontWeight: 'normal',
                marginBottom: '2em',
              }}
            />
            <Button primary size='huge' style={{ backgroundColor: '#4CAF50' }}>
              Tìm hiểu thêm
              <Icon name='right arrow' style={{ marginLeft: '0.5em' }} />
            </Button>
          </Container>
        </Segment>

        {/* Vision Statement */}
        <Container text style={{ padding: '4em 0' }}>
          <Header as='h2' textAlign='center' style={{
            fontSize: isMobile ? '1.8em' : '2.5em',
            marginBottom: '1em',
            color: '#2C3E50'
          }}>
            Tầm nhìn bền vững
          </Header>
          <p style={{
            fontSize: isMobile ? '1.1em' : '1.3em',
            lineHeight: '1.6',
            color: '#34495E',
            textAlign: 'center'
          }}>
            Chúng tôi cam kết xây dựng một tương lai bền vững thông qua việc cân bằng giữa phát triển kinh tế, 
            trách nhiệm xã hội và bảo vệ môi trường. Mỗi quyết định của chúng tôi đều hướng đến mục tiêu tạo ra 
            giá trị lâu dài cho cộng đồng và thế hệ sau.
          </p>
        </Container>

        {/* Impact Statistics */}
        <Segment style={{ padding: '4em 0', backgroundColor: '#F5F5F5' }}>
          <Container>
            <Grid columns={4} stackable textAlign='center'>
              {impactStats.map((stat, index) => (
                <Grid.Column key={index}>
                  <Statistic>
                    <Statistic.Value style={{ color: '#4CAF50' }}>{stat.value}</Statistic.Value>
                    <Statistic.Label style={{ color: '#2C3E50' }}>{stat.label}</Statistic.Label>
                  </Statistic>
                </Grid.Column>
              ))}
            </Grid>
          </Container>
        </Segment>

        {/* Sustainability Pillars */}
        <Container style={{ padding: '4em 0' }}>
          <Header as='h2' textAlign='center' style={{
            fontSize: isMobile ? '1.8em' : '2.5em',
            marginBottom: '2em',
            color: '#2C3E50'
          }}>
            Ba trụ cột phát triển bền vững
          </Header>
          <Grid columns={3} stackable>
            {sustainabilityPillars.map((pillar, index) => (
              <Grid.Column key={index}>
                <Card fluid style={{ height: '100%', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                  <Card.Content textAlign='center'>
                    <Icon name={pillar.icon} size='huge' style={{ color: '#4CAF50', margin: '0.5em 0' }} />
                    <Card.Header style={{ fontSize: '1.5em', margin: '0.5em 0', color: '#2C3E50' }}>
                      {pillar.title}
                    </Card.Header>
                    <Card.Description style={{ fontSize: '1.1em', color: '#34495E', lineHeight: '1.4' }}>
                      {pillar.description}
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            ))}
          </Grid>
        </Container>

        {/* Commitment Section */}
        <Segment
          inverted
          style={{
            padding: '4em 0',
            backgroundImage: 'linear-gradient(135deg, #43A047 0%, #1B5E20 100%)',
          }}
        >
          <Container text textAlign='center'>
            <Header
              as='h2'
              content='Cam kết của chúng tôi'
              inverted
              style={{
                fontSize: isMobile ? '1.8em' : '2.5em',
                marginBottom: '1em',
              }}
            />
            <p style={{
              fontSize: isMobile ? '1.1em' : '1.3em',
              lineHeight: '1.6',
              marginBottom: '2em'
            }}>
              Phước Sơn cam kết không ngừng cải tiến và đổi mới để đạt được các mục tiêu phát triển bền vững,
              đồng thời tạo ra giá trị tích cực cho cộng đồng và môi trường.
            </p>
            <Button size='huge' inverted>
              Xem các hoạt động bền vững
              <Icon name='file pdf outline' style={{ marginLeft: '0.5em' }} />
            </Button>
          </Container>
        </Segment>

        {/* Goals Section */}
        <Container style={{ padding: '4em 0' }}>
          <Grid columns={2} stackable>
            <Grid.Column>
              <Header as='h2' style={{
                fontSize: isMobile ? '1.8em' : '2.5em',
                color: '#2C3E50',
                marginBottom: '1em'
              }}>
                Mục tiêu phát triển bền vững
              </Header>
              <p style={{
                fontSize: '1.1em',
                lineHeight: '1.6',
                color: '#34495E'
              }}>
                Chúng tôi cam kết đóng góp vào các mục tiêu phát triển bền vững của Liên Hợp Quốc,
                tập trung vào các lĩnh vực:
              </p>
              <ul style={{
                fontSize: '1.1em',
                lineHeight: '1.8',
                color: '#34495E',
                paddingLeft: '1.5em'
              }}>
                <li>Bảo vệ môi trường và đa dạng sinh học</li>
                <li>Phát triển nguồn nhân lực bền vững</li>
                <li>Tăng trưởng kinh tế xanh</li>
                <li>Đổi mới sáng tạo và công nghệ</li>
              </ul>
            </Grid.Column>
            <Grid.Column>
              <Segment placeholder style={{ height: '100%', backgroundColor: '#F5F5F5' }}>
                <Header icon>
                  <Icon name='chart line' />
                  Biểu đồ tiến độ
                </Header>
                <Button primary style={{ backgroundColor: '#4CAF50' }}>Xem chi tiết</Button>
              </Segment>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default SustainabilityLayout;
